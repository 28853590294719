<template>
    <div class="tree-list">
        <!--h3 class="g-title">Organisation</h3-->
        <!--v-container>
          <v-row>
            <v-col-->
        <v-text-field class="searchbox" v-model="search_site_name" label="Organisation Search"
          :rules="search_rules" hide-details="auto" clearable
            @click:clear="fetchSites" v-on:keypress.enter="searchSites"></v-text-field>
            <!--/v-col>
            <v-col>
        <v-btn @click="searchSites">Refresh</v-btn>
            </v-col>
          </v-row>
        </v-container-->
        <node-tree v-for="treedict,idx in forestList" :key="idx" :node="treedict"
          :depth="depth" v-on:nodeclicked="emitNode" v-on:datadropped="dropEmitRelay"></node-tree>
    </div>
</template>

<script>

import NodeTree from "./NodeTree";

export default {
  props: {
  },
  components: {
    NodeTree
  },
  data() {
    return {
        search_site_name: null,
        search_rules: [
          //value => !!value || 'Required.',
          value => (value && value.length >= 3) || 'Min 3 characters',
        ],

        forestList: [],
        depth: 0
    }
  },
  mounted(){
    this.fetchSites();
  },
  methods: {
    fetchSites(){
      //this.$emit('loadstart');
      var forestList = [];
      this.$api.get(this.$orgmanurl + '/company/get')
      .then(response => {
        //console.log(response.data);
        if (response.data["code"] == "list_users_company")
        {
          const ucl = response.data["users-company-list"];
          ucl.forEach( (abspathtuples) => {
            let abspathnames = [];
            abspathtuples.forEach( (sitetuple) => abspathnames.push(sitetuple[1]) );
            let usersitetuple = abspathtuples[abspathtuples.length-1];
            //console.log(usersitetuple);
            var companydict = { 'name': usersitetuple[1], "id": usersitetuple[0], 'abspath': abspathnames, 'expanded': false, 'children': null };
            forestList.push(companydict);
          });

          this.forestList = forestList;
          if (this.forestList.length > 0) {
            this.expandFirstRootNode();
            this.emitNode(this.forestList[0]);//Fetch the data linked to the first root node
          }
        }
        //this.$emit('loadcomplete');
      });
    },
    searchSites(){
      if ((this.search_site_name === null)||(this.search_site_name.length < 3)) {
        this.fetchSites();
      } else {
        //this.$emit('loadstart');      
        var forestList = [];
        this.$api.post(this.$orgmanurl + '/company/search',{"sitename":this.search_site_name})
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "search_child_companies")
          {
            const ucl = response.data["users-company-list"];
            ucl.forEach( (abspathtuples) => {
              let abspathnames = [];
              abspathtuples.forEach( (sitetuple) => abspathnames.push(sitetuple[1]) );
              let usersitetuple = abspathtuples[abspathtuples.length-1];
              //console.log(usersitetuple);
              var companydict = { 'name': usersitetuple[1], "id": usersitetuple[0], 'abspath': abspathnames, 'expanded': false, 'children': null };
              forestList.push(companydict);
            });

            this.forestList = forestList;
            if (this.forestList.length > 0) {
              this.expandFirstRootNode();
              this.emitNode(this.forestList[0]);//Fetch the data linked to the first root node
            }
          }
          //this.$emit('loadcomplete');
        });
      }
    },
    emitNode(node) {
        //console.log(node);
        this.$emit('ouclicked', node);
    },
    expandFirstRootNode() {
        this.$api.post(this.$orgmanurl + '/company/children',{"siteid":this.forestList[0].id})
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_child_companies")
          {
            this.forestList[0].children = [];
            response.data["child-companies-list"].forEach(sitetuple => {
              var siteid = sitetuple[0];
              var sitename = sitetuple[1];
              var newdict = {"name": sitename, "id": siteid, "children":null};
              this.forestList[0].children.push(newdict);
            });
          }
          //this.$emit('loadcomplete');
          if (this.forestList[0].children && (this.forestList[0].children.length > 0)) {
              //this.node.expanded = !this.node.expanded;
              this.$set(this.forestList[0], 'expanded', true);
          }
        });
    },
    dropEmitRelay(droppeddata) {
      this.$emit('datadropped', droppeddata);
      //this.$parent.$emit('ouclicked', this.node);
    },
  }
};
</script>

<style scoped>
/*.g-title {
  padding-left: 16px;
  margin: 6px 0;
}*/
.searchbox {
  padding-left: 16px;
  padding-right: 16px;
  margin: 6px 0;
}
/*.tree-list {
  padding-left: 16px;
  margin: 6px 0;
}*/
</style>
<template>
  <v-container>
    <v-row v-if="pump['daq'].length > 0">
      <v-col cols="12" sm="3">
        <v-list v-if='pump["daq"][flowmane]==1' dense>
          <v-list-item>PumpState:&nbsp;<h4>{{ represent_pump_state(pump) }}</h4></v-list-item>
          <v-list-item class="blue--text"><b>Flowrate Mode</b></v-list-item>
          <v-list-item>Setpoint: {{ pump["daq"][flowsetp].toFixed(2) }} LPM</v-list-item>
        </v-list>
        <v-list v-else dense>
          <v-list-item>PumpState:&nbsp;<h4>{{ represent_pump_state(pump) }}</h4></v-list-item>
          <v-list-item class="red--text"><b>Pressure Mode</b></v-list-item>
          <v-list-item :class='{"red white--text": pump["daq"][psplei]==0}'>SPHigh: {{ pump["daq"][psphighi].toFixed() }} PSI</v-list-item>
          <v-list-item :class='(pump["daq"][psplei]==1)? "red white--text":"grey--text"'>SPLow: {{ pump["daq"][psplowi].toFixed() }} PSI</v-list-item>
          <!--v-list-item>PEstim: {{ pump["daq"][pestimi].toFixed(2) }}</v-list-item-->
          <!--v-list-item-group>
            <v-list-item v-if="pump['events'].length > 0" @click.prevent="showdtcdialog()">NumEvents: {{ pump['events'].length }}</v-list-item>
          </v-list-item-group-->
        </v-list>        
      </v-col>
      <v-col cols="12" sm="4">
        <v-container>
          <v-row>
            <v-col>
              <apexchart height="300" type="radialBar" :options="pressureoptions" :series="pressurePercentage"></apexchart>
            </v-col>
          </v-row>
          <v-row align="stretch">
            <v-col class="d-flex justify-center align-center">{{ pump["daq"][pdispli].toFixed() }} PSI</v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" sm="4">
        <v-container>
          <v-row>
            <v-col>
              <apexchart height="300px" type="radialBar" :options="flowoptions" :series="flowPercentage"></apexchart>
            </v-col>
          </v-row>
          <v-row align="stretch">
            <v-col class="d-flex justify-center align-center">{{ pump["daq"][flowfilt1i].toFixed(2) }} LPM</v-col>
            <!--v-col class="d-flex justify-center align-center">{{ pump["daq"][flowfilt2i].toFixed(2) }} LPM</v-col-->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "HomeTab",
  props: {
      pump: null
  },
  computed: {
    pressurePercentage: function() {
      //return [67.23];
      return [((100*this.pump["daq"][this.pestimi])/90000).toFixed(2)];
    },
    flowPercentage: function() {
      //return [67.23];
      return [((100*this.pump["daq"][this.flowfilt1i])/10).toFixed(2)];
    }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");

    this.flowfilt1i = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt1");
    this.pestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.pressureEstimator.s32Pressure_Estim");
    this.pdispli = vhmi_mcdvars_list.indexOf("app.hmiViewModel.s32PressureDisplay");
    this.flowfilt2i = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt2");
    this.psphighi = vhmi_mcdvars_list.indexOf("app.machine.sapCommandManager.pressureSetpointLogic.s32PressureSetpoint_High");
    this.psplowi = vhmi_mcdvars_list.indexOf("app.machine.sapCommandManager.pressureSetpointLogic.s32PressureSetpoint_Low");
    this.psplei = vhmi_mcdvars_list.indexOf("app.machine.sapCommandManager.pressureSetpointLogic.boPressureSetpoint_Low_En");

    this.flowmane = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControlMode.pressureController.boManualFlowRateEnable");
    this.flowsetp = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControlMode.pressureController.s32ManualFlowRateSetpoint");
  },
  data() {
      return {
        u16hmistatei: null,
        flowfilt1i:null,
        pestimi:null,
        pdispli:null,
        flowfilt2i:null,
        psphighi:null,
        psplowi:null,
        psplei:null,
        flowmane:null,
        flowsetp:null,

        pressureoptions: {
            colors: ["#FF4747"],
            plotOptions: {
            radialBar: {
                startAngle: -135, endAngle: 135,
                track: { background: '#300', startAngle: -135, endAngle: 135 },
                dataLabels: {
                name: { show: true },
                value: { 
                  fontSize: "30px", show: false,
                  formatter: function (w) {
                    return w;
                  }
                 }
                }
            }
            },
            fill: {
            type: "gradient",
            gradient: {
                shade: "dark", type: "horizontal",
                gradientToColors: ["#87D400"], stops: [0, 100]
            }
            },
            stroke: { lineCap: "butt" },
            labels: ["Output Pressure"]
        },
        flowoptions: {
            colors: ["#20E6FF"],
            plotOptions: {
            radialBar: {
                startAngle: -135, endAngle: 135,
                track: { background: '#333', startAngle: -135, endAngle: 135 },
                dataLabels: {
                name: { show: true },
                value: { fontSize: "30px", show: false }
                }
            }
            },
            fill: {
            type: "gradient",
            gradient: {
                shade: "dark", type: "horizontal",
                gradientToColors: ["#8700F9"], stops: [0, 100]
            }
            },
            stroke: { lineCap: "butt" },
            labels: ["Output FlowRate"]
        }
    }    
  }
}
</script>

<template>
<div id="vhmidashboard">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>VHMI Dashboard</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container class="mt-5">
      <v-row>
        <v-col cols="6" md="1">              
          <v-tooltip v-if="sio_connection_status" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="green" dark v-bind="attrs" v-on="on"> mdi-cloud-sync </v-icon>
            </template>
            <span>Connected</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="red" dark v-bind="attrs" v-on="on"> mdi-cloud-alert </v-icon>
            </template>
            <span>No Connection!</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" sm="4" md="2">
          <v-btn color="primary" @click.prevent="refresh_vhmi_pumps">Reload Pumps</v-btn>
        </v-col>
      </v-row>
      <!--v-row v-if="(pumpslist.length > 0) && (pumpdata.length > 0)"-->
      <v-row v-if="(Object.keys(pumpdatadict).length > 0)">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="pumpdata in pumpdataarr" :key="pumpdata['ipaddr']">
      <!--v-row v-if="(this.$store.getters.get_vhmi_pumpips().length > 0)">
        <template v-for="pumpip in this.$store.getters.get_vhmi_pumpips()">
        <v-col cols="12" sm="3" v-if="pumpip in pumpdatadict" :set="pumpdata=pumpdatadict[pumpip]" :key="pumpip"-->
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2">
              <v-card-title :class="['headline mb-1 darken-2 white--text', get_pump_color(pumpdata)]">
                {{ pumpdata['nname'] }}
                <v-spacer></v-spacer>
                <v-btn @click.prevent="remove_vhmi_pump(pumpdata['ipaddr'])">X</v-btn>
              </v-card-title>
              <v-card-text v-if="pumpdata['connstate']!=null">
                Updated @ {{ convertUTCDateToLocalDate(pumpdata["connstate"]["rtime"]) }}
                <!-- SeqIdx: {{ pumpdata["connstate"]["seqidx"] }}<br/-->
                <!-- State: {{ represent_pump_state(pumpdata) }}<br /-->
              </v-card-text>
              <v-card-text class="black--text text-center">
                <h4>{{ represent_pump_state(pumpdata) }}</h4>
              </v-card-text>
              <v-list v-if="pumpdata['daq'].length > 0" dense>
                <v-list-item inactive>Pressure: {{ pumpdata["daq"][pestimi].toFixed() }} PSI</v-list-item>
                <v-list-item inactive>Flowrate: {{ pumpdata["daq"][flowfilt1i].toFixed(2) }} LPM</v-list-item>
                <v-list-item inactive>IP Address: {{ pumpdata["ipaddr"] }}</v-list-item>
                <v-list-item inactive>Firmware: {{ getESPCMVersion(pumpdata['daq']) }}</v-list-item>
                <v-list-item inactive v-if="pumpdata['events'].length > 0">NumEvents: {{ pumpdata['events'].length }}</v-list-item>
              </v-list>
              <v-card-actions  class="justify-center">
                <v-btn color="primary" @click.prevent="showpump(pumpdata['ipaddr'])" :disabled='(pumpdata["connstate"]==null)||(pumpdata["connstate"]["state"]!="daqevents")'>Show Pump</v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
        <!--/template-->
      </v-row>

      <v-dialog v-model="display_pump" transition="dialog-bottom-transition" max-width="800">
        <VhmiPumpTile v-if="display_pump" :pump="pumpdatadict[display_pumpip]" />
      </v-dialog>
    </v-container>
</div>
</template>

<script>
  import VhmiPumpTile from "../components/VhmiPumpTile.vue";
  import vhmi_mcdvars_list from '../plugins/mcdvariables';
  import vhmiutils from '../plugins/vhmi_utilities';
  import dateutils from '../plugins/date_utilities';

  export default {
    name: "VHMIDashboard",
    components: {
      VhmiPumpTile
    },
    data: () => ({
      display_pump_idx: 0,
      display_pump: false,

      flowfilt1i: null,
      pestimi: null,
      boiserrori: null,
      boiswarni: null,
      u16hmistatei: null,
      au8AppVersionFullStringi: null
    }),
    computed:{
      sio_connection_status() {
        return (this.$store.state.socketid != null);
        //"Connected with sid: " + this.$store.state.socketid + "\n";
      },
      pumpdatadict(){
        return this.$store.state.vhmipumpdata;
      },
      pumpdataarr(){
        const pump_data_dict = this.$store.state.vhmipumpdata;
        const vhmipumpips = this.$store.getters.get_vhmi_pumpips();
        //console.log(vhmipumpips);
        //return vhmipumpips.map((ipaddr) => pump_data_dict[ipaddr] );
        //return vhmipumpips.map(function (ipaddr) { return pump_data_dict[ipaddr] });
        let sortedPumpDataArray = new Array();
        vhmipumpips.forEach(function(ipaddr) {
          if (ipaddr in pump_data_dict)
            sortedPumpDataArray.push(pump_data_dict[ipaddr]);
        });
        return sortedPumpDataArray;
      }
    },
    created(){
      this.enum_pump_states = vhmiutils.enum_pump_states;
      this.represent_pump_state = vhmiutils.represent_pump_state;

      this.convertUTCDateToLocalDate = dateutils.convertUTCDateToLocalDate;

      this.flowfilt1i = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt1");
      this.pestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.pressureEstimator.s32Pressure_Estim");
      this.boiserrori = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsError_Aggregated_Latched");
      this.boiswarni = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsLatchedDTC_Warning");
      this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");

      this.au8AppVersionFullStringi = vhmi_mcdvars_list.indexOf("app.applicationInfo.codeInformationBlock_App.stVersionInformation.au8VersionFullString");
    },
    methods: {
      refresh_vhmi_pumps() {
        const authtoken = this.$store.getters.get_session_id();
        const vhmipumpids = this.$store.getters.get_vhmi_pumpids();
        const monpumpsdict = {"authtoken": authtoken,"pumpidlist":vhmipumpids};
        this.$socket.emit("configure_monitored_pumps", monpumpsdict);
      },
      remove_vhmi_pump(ipaddr) {
        //console.log("Delete: ", ipaddr);
        this.$store.commit('del_vhmi_pump', ipaddr);
        const vhmipumpids = this.$store.getters.get_vhmi_pumpids();
        const authtoken = this.$store.getters.get_session_id();
        const monpumpsdict = {"authtoken": authtoken,"pumpidlist":vhmipumpids};
        this.$socket.emit("configure_monitored_pumps", monpumpsdict);
      },
      showpump(ipaddr) {
        //console.log(ipaddr);
        this.display_pumpip = ipaddr;
        this.display_pump = true;
      },
      get_pump_color(pumpdata) {
        if ((pumpdata["connstate"] == null) || (pumpdata["connstate"]["state"] == 'offline'))
          return 'black';
        else if (pumpdata["connstate"]["state"] != 'daqevents')
          return 'grey';
        else if (pumpdata['daq'][this.boiserrori])
          return 'red';
        else
          return pumpdata['daq'][this.boiswarni]?'orange':'blue';        
      },
      getESPCMVersion: function(daqdata) {
        //const daqdata = this.pump["daq"];
        return String.fromCharCode.apply(null,daqdata[this.au8AppVersionFullStringi]);
      }
    }
  }
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"vhmidashboard"}},[_c('v-toolbar',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("VHMI Dashboard")])],1),_c('v-divider'),_c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"1"}},[(_vm.sio_connection_status)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-sync ")])]}}],null,false,4294594984)},[_c('span',[_vm._v("Connected")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-alert ")])]}}])},[_c('span',[_vm._v("No Connection!")])])],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.refresh_vhmi_pumps.apply(null, arguments)}}},[_vm._v("Reload Pumps")])],1)],1),((Object.keys(_vm.pumpdatadict).length > 0))?_c('v-row',_vm._l((_vm.pumpdataarr),function(pumpdata){return _c('v-col',{key:pumpdata['ipaddr'],attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{class:['headline mb-1 darken-2 white--text', _vm.get_pump_color(pumpdata)]},[_vm._v(" "+_vm._s(pumpdata['nname'])+" "),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.remove_vhmi_pump(pumpdata['ipaddr'])}}},[_vm._v("X")])],1),(pumpdata['connstate']!=null)?_c('v-card-text',[_vm._v(" Updated @ "+_vm._s(_vm.convertUTCDateToLocalDate(pumpdata["connstate"]["rtime"]))+" ")]):_vm._e(),_c('v-card-text',{staticClass:"black--text text-center"},[_c('h4',[_vm._v(_vm._s(_vm.represent_pump_state(pumpdata)))])]),(pumpdata['daq'].length > 0)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"inactive":""}},[_vm._v("Pressure: "+_vm._s(pumpdata["daq"][_vm.pestimi].toFixed())+" PSI")]),_c('v-list-item',{attrs:{"inactive":""}},[_vm._v("Flowrate: "+_vm._s(pumpdata["daq"][_vm.flowfilt1i].toFixed(2))+" LPM")]),_c('v-list-item',{attrs:{"inactive":""}},[_vm._v("IP Address: "+_vm._s(pumpdata["ipaddr"]))]),_c('v-list-item',{attrs:{"inactive":""}},[_vm._v("Firmware: "+_vm._s(_vm.getESPCMVersion(pumpdata['daq'])))]),(pumpdata['events'].length > 0)?_c('v-list-item',{attrs:{"inactive":""}},[_vm._v("NumEvents: "+_vm._s(pumpdata['events'].length))]):_vm._e()],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","disabled":(pumpdata["connstate"]==null)||(pumpdata["connstate"]["state"]!="daqevents")},on:{"click":function($event){$event.preventDefault();return _vm.showpump(pumpdata['ipaddr'])}}},[_vm._v("Show Pump")])],1)],1)]}}],null,true)})],1)}),1):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"800"},model:{value:(_vm.display_pump),callback:function ($$v) {_vm.display_pump=$$v},expression:"display_pump"}},[(_vm.display_pump)?_c('VhmiPumpTile',{attrs:{"pump":_vm.pumpdatadict[_vm.display_pumpip]}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
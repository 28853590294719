<template>    
    <v-app-bar app  clipped-left color="primary" dark>
      <v-app-bar-nav-icon class="grey--text" @click="toggleSidebar"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Quantum SPT"
          contain
          min-width="100"
          :src="companyLogo"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>
      
      <v-btn icon to="/vhmi">
        <v-badge
          :content="alert_messages_count"
          :value="alert_messages_count"
          color="red"
          overlap
        >
          <v-icon>far fa-bell</v-icon>
        </v-badge>
      </v-btn>
      <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
        <router-link to="/login">
          <v-avatar size="40">
              <v-img src="@/assets/avatar.png"></v-img>
          </v-avatar>
        </router-link>
      </v-badge>
    </v-app-bar>
</template>

<script>
import vhmi_mcdvars_list from '../plugins/mcdvariables';
export default {
  name: 'TopBar',
  data() {
    return {
      /*boiserrori: null,
      boiswarni: null,*/
      //alert_messages_count: 1,
      companyLogo: require('@/assets/logo.png')
    }
  },
  computed: {
    alert_messages_count() {
      var errorcount = 0;
      var warncount = 0;

      const pump_data_dict = this.$store.state.vhmipumpdata;
      const vhmi_pump_iplist = Object.keys(pump_data_dict);

      const boiserrori = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsError_Aggregated_Latched");
      const boiswarni = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsLatchedDTC_Warning");

      vhmi_pump_iplist.forEach(function(ipaddr) {
        const pumpdata = pump_data_dict[ipaddr];
        if ((pumpdata["connstate"] != null) && (pumpdata["connstate"]["state"] == 'daqevents')) {
          //console.log(ipaddr, boiserrori, pumpdata['daq']);
          if (pumpdata['daq'][boiserrori])
            errorcount++;
          else if (pumpdata['daq'][boiswarni])
            warncount++;
        }
      });

      return errorcount+warncount;
    }
  },
  /*created(){
    this.boiserrori = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsError_Aggregated_Latched");
    this.boiswarni = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControllerSM.boIsLatchedDTC_Warning");
  },*/
  methods:{
    toggleSidebar(){
      this.$emit("togglesidebar");
    }
  }
}
</script>
<style scoped>
  @media print {
    .v-app-bar {
      visibility: hidden;
    }
  }
</style>
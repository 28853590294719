<template>
<div id="resource_permissions_page" style="height:86vh">
    <!--v-toolbar class="mt-2" flat>
      <v-toolbar-title>ResourcePermissions</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider-->
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col class="fill-height" cols="3">        
        <v-card class="fill-height"  style="overflow-y: scroll">
          <v-card-title>
            <h3>Organisation</h3>
          </v-card-title>
          <MyTreeView @ouclicked="fetchPermissions" />
        </v-card>
      </v-col>

      <v-col  cols="9" class="text-center fill-height">
        <v-card class="fill-height" v-if="datafetch_in_progress" cols="9">
          <v-row class="fill-height d-flex justify-center">
            <v-col cols="6">
              <LoadingBar />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="fill-height text-left" v-else-if="selectedNode !== null" cols="9" style="overflow-y: scroll">
          <v-card-title><h3>{{ selectedNode.name }} - Permissions</h3></v-card-title>
          <v-card-subtitle>{{ siteid }} <b>|</b> {{ lastauthor }} <b>|</b> {{ editedon }}</v-card-subtitle>
        <v-card-text>
          <a @click="selectall_variants">Select All</a> | <a @click="selected_variants_list=[]">Select None</a> 
          <v-list>
            <v-list-item-group v-model="selected_variants_list" multiple active-class="">
            <v-list-item
              v-for="item in available_variants_list" :key="`types-${item[0]}`" :value="item[0]">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item[0] }} ({{item[1]}})</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-btn class="ml-3 my-3" color="primary" @click.prevent="setPermissions">Apply</v-btn>
          &nbsp;
          <v-btn class="ml-3 my-3" color="primary" @click.prevent="resetPermissions">Reset</v-btn>
        </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import MyTreeView from "../components/MyTreeView";
  import LoadingBar from "../components/LoadingBar";

  export default {
    name: "ResourcePermissionsPage",

  components: {
    MyTreeView,
    LoadingBar
  },
    data: () => ({
      datafetch_in_progress: false,
      selectedNode: null,
      
      siteid: null,
      sitename: null,
      available_variants_list: [],
      selected_variants_list:[],
      site_permissions_list: [],
      lastauthor: null,
      editedon: null,
    }),
    methods: {
      fetchPermissions (recvdnode) {
        this.datafetch_in_progress = true;
        this.siteid = null;
        this.sitename = null;
        this.available_variants_list = [];
        this.selected_variants_list = [];
        this.site_permissions_list = [];
        this.lastauthor = null;
        this.editedon = null;
        this.selectedNode = null;
        //console.log("test");

        this.$api.post(this.$rsrcdburl + '/permissions',{"code":"get_site_permissions","siteid":recvdnode.id})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "get_site_permissions")
                {
                  const permlist = response.data["permissions"];
                  //console.log(permlist);
                  if (recvdnode.id == permlist["siteid"]){
                    this.siteid = permlist["siteid"];
                    this.sitename = permlist["sitename"];
                    this.available_variants_list = permlist["variantslist"];
                    this.site_permissions_list = permlist["permissions"];
                    //console.log(this.site_permissions_list);
                    this.selected_variants_list = this.site_permissions_list;
                    this.lastauthor = permlist["author"];
                    this.editedon = permlist["editedon"];
                  } else {
                    this.siteid = recvdnode.id;
                    this.sitename = recvdnode.name;
                    this.available_variants_list = permlist["variantslist"];
                    this.site_permissions_list = [];
                    this.selected_variants_list = this.site_permissions_list;
                    this.lastauthor = "None";
                    this.editedon = "00-00-0000";
                  }
                  this.selectedNode = recvdnode;
                }
                //this.$emit('loadcomplete');
                this.datafetch_in_progress = false;
              });

        return;
      },
      setPermissions () {
        this.datafetch_in_progress = true;
        //console.log("test");
        const selnode = this.selectedNode;
        this.$api.post(this.$rsrcdburl + '/setperms',
              {"code":"set_site_permissions",
              "siteid":selnode.id,
              "permissions":this.selected_variants_list})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "set_site_permissions")
                {
                  const permlist = response.data["permissions"];
                  if (selnode.id == permlist["siteid"]){
                    this.siteid = permlist["siteid"];
                    this.sitename = permlist["sitename"];
                    this.available_variants_list = permlist["variantslist"];
                    this.site_permissions_list = permlist["permissions"];
                    this.lastauthor = permlist["author"];
                    this.editedon = permlist["editedon"];
                    //console.log(site_permissions_list);
                  }
                }
                //this.$emit('loadcomplete');
                this.datafetch_in_progress = false;
              });

        return;
      },
      resetPermissions () {
        this.selected_variants_list = this.site_permissions_list;
      },
      selectall_variants() {
        this.selected_variants_list = this.available_variants_list.map((currentVal) => currentVal[0]);
      }
    }
  }
</script>
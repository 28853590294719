<template>
  <v-app>
    <TopBar @togglesidebar="toggleSidebar" />
    <SideBar :visibility="sidebarVisibility" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from './components/TopBar';
import SideBar from './components/SideBar';
export default {
  name: 'App',
  components: {
    SideBar,
    TopBar
  },
  data: () => ({
    sidebarVisibility: true,
  }),
  methods: {
    toggleSidebar(){
      this.sidebarVisibility = !this.sidebarVisibility;
    }
  }
};
</script>

<style>
html {
  overflow-y:auto !important;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./vuexstore";
import Home from "../views/Home.vue";
import DTCDetailed from "../views/DTCDetailed.vue";
import ResourcesList from "../views/ResourcesList.vue";
import ResourcePage from "../views/ResourcePage.vue";
import ResourcePermissionsPage from "../views/ResourcePermissionsPage.vue";
import SitesPage from "../views/SitesPage.vue";
import UsersPage from "../views/UsersPage.vue";
import UserSearchPage from "../views/UserSearchPage.vue";
import PumpsPage from "../views/PumpsPage.vue";
import PumpReporterPage from "../views/PumpReporterPage.vue";
import PumpsOnlinePage from "../views/PumpsOnlinePage.vue";
import RouterDetailed from "../views/RouterDetailed.vue";
import RoutersPage from "../views/RoutersPage.vue";
import RoutersUnknownPage from "../views/RoutersUnknownPage.vue";
import RoutersOnlinePage from "../views/RoutersOnlinePage.vue";
import RouterSearchPage from "../views/RouterSearchPage.vue";
import PumpReporterVariables from "../views/PumpReporterVariables.vue";
import PumpSearchPage from "../views/PumpSearchPage.vue";
import VHMIDashboard from "../views/VHMIDashboard.vue";
import PumpEventLog from "../views/PumpEventLog.vue";
import PumpEventSummary from "../views/PumpEventSummary.vue";
import EventSiteReport from "../views/EventSiteReport.vue";
import PumpHealthReport from "../views/PumpHealthReport.vue";
import PumpHealthProgress from "../views/PumpHealthProgress.vue";
import LoginPage from "../views/LoginPage.vue";
import HMIReference from "../views/HMIReference.vue";
import HMIScreenRef from "../views/HMIScreenRef.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home, meta: { requiresAuth: true} },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/dtc/:shortlabel", name: "DTCDetailed", component: DTCDetailed, meta: { requiresAuth: true} },
  { path: "/resources", name: "ResourcesList", component: ResourcesList, meta: { requiresAuth: true} },
  { path: "/resource/:id", name: "ResourcePage", component: ResourcePage, props:true, meta: { requiresAuth: true} },
  { path: "/permissions", name: "ResourcePermissionsPage", component: ResourcePermissionsPage, props:true, meta: { requiresAuth: true} },
  { path: "/sites", name: "SitesPage", component: SitesPage, meta: { requiresAuth: true} },
  { path: "/users", name: "UsersPage", component: UsersPage, meta: { requiresAuth: true} },
  { path: "/usersearch", name: "UserSearchPage", component: UserSearchPage, meta: { requiresAuth: true} },
  { path: "/pumpreporter", name: "PumpReporterPage", component: PumpReporterPage, meta: { requiresAuth: true} },
  { path: "/pumphealth", name: "PumpHealthReport", component: PumpHealthReport, meta: { requiresAuth: true} },
  { path: "/pumpprogress", name: "PumpHealthProgress", component: PumpHealthProgress, meta: { requiresAuth: true} },
  { path: "/pumpsonline", name: "PumpsOnlinePage", component: PumpsOnlinePage, meta: { requiresAuth: true} },
  { path: "/routers", name: "RoutersPage", component: RoutersPage, meta: { requiresAuth: true} },
  { path: "/routersonline", name: "RoutersOnlinePage", component: RoutersOnlinePage, meta: { requiresAuth: true} },
  { path: "/routersunknown", name: "RoutersUnknownPage", component: RoutersUnknownPage, meta: { requiresAuth: true} },
  { path: "/routersearch", name: "RouterSearchPage", component: RouterSearchPage, meta: { requiresAuth: true} },
  { path: "/router/:label", name: "RouterDetailed", component: RouterDetailed, meta: { requiresAuth: true} },
  { path: "/pumpvars", name: "PumpReporterVariables", component: PumpReporterVariables, meta: { requiresAuth: true} },
  { path: "/pumps", name: "PumpsPage", component: PumpsPage, meta: { requiresAuth: true} },
  { path: "/pumpsearch", name: "PumpSearchPage", component: PumpSearchPage, meta: { requiresAuth: true} },
  { path: "/vhmi", name: "VHMIPage", component: VHMIDashboard, meta: { requiresAuth: true} },
  { path: "/events", name: "PumpEventLog", component: PumpEventLog, meta: { requiresAuth: true} },
  { path: "/eventsum", name: "PumpEventSummary", component: PumpEventSummary, meta: { requiresAuth: true} },
  { path: "/sitereport", name: "EventSiteReport", component: EventSiteReport, meta: { requiresAuth: true} },
  { path: "/hmiref", name: "HMIRefPage", component: HMIReference, meta: { requiresAuth: true} },
  { path: "/hmiscreen", name: "HMIScreenRef", component: HMIScreenRef, meta: { requiresAuth: true} },
  { path: "/diagnostics", name: "AllDTCsTable",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AllDTCsTable.vue"), meta: { requiresAuth: true}
  },
  // and finally the default route, when none of the above matches:
  { path: "*", name: "PageNotFound", component: PageNotFound, meta: { requiresAuth: false}  }
];

const router = new VueRouter({
  mode: "history", /*hash*/
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.get_session_id() == null) {
      next({ name: 'LoginPage' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;

<template>
<div id="userspage" :style="{'height':dataCardHeight+'px','min-height':'240px'}">
    <!--v-toolbar class="mt-2" flat>
      <v-toolbar-title>User Directory</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider-->
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col class="gtm-col-padding fill-height" cols="12" sm="4" md="3">
        <v-card class="gtm-card-scroll fill-height" style="overflow-y: scroll">
          <MyTreeView @ouclicked="fetchUsers" @datadropped="onUserDrop"/>
        </v-card>
      </v-col>

      <v-col class="gtm-col-padding text-center fill-height" cols="12" sm="8" md="9">
        <v-card class="gtm-card-scroll fill-height" v-if="datafetch_in_progress" style="overflow-y: scroll">
          <v-container fluid class="fill-height">
            <v-row class="fill-height d-flex justify-center">
              <v-col cols="6">
                <LoadingBar />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card class="gtm-card-scroll fill-height" v-else-if="selectedNode !== null" style="overflow-y: scroll">
        <v-card-title>
          <h3>{{ selectedNode.name }} - Users ({{ userlist.length }})</h3>
          <v-spacer class="d-none d-md-block"></v-spacer>
          <div class="d-flex justify-space-around pr-6">
          <v-btn-toggle v-model="isRecursiveFetch" color="black">
            <v-btn :value="true">All</v-btn>
            <v-btn :value="false">Child</v-btn>
          </v-btn-toggle>
          </div>
          <span>&nbsp;</span>
          <v-dialog v-model="addUserDialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Add Users
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add New User</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Firstname*" v-model="addUsrData.fname" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Lastname*" v-model="addUsrData.lname" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Email*" v-model="addUsrData.email" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ adduser_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addUserClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="addUserConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-dialog v-model="deleteUserDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete {{ seletectedDelUsr["email"] }} ? </v-card-text>
            <v-card-text> {{ deluser_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteUserConfirm" > Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="moveUsertoOUDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> Confirm Move </v-card-title>
                <v-card-text> Are you sure you want to move "{{ selectedUsertoMove["name"] }}" to "{{ selectedUsertoMove["newsitename"] }}"? </v-card-text>
                <v-card-text> {{ moveUsertoOU_error_msg }} </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="moveConfirmClick" > Move </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center auto-collapse" style="width:5%"> Move User </th>
                    <th class="text-center" style="width:5%"> Simulate User </th>
                    <th class="text-left"> Email </th>
                    <th class="text-left" style="width:15%"> FirstName </th>
                    <th class="text-left" style="width:15%"> LastName </th>
                    <th class="text-left auto-collapse" style="width:20%"> Approved By </th>
                    <th class="text-center" style="width:5%"> DeleteUser </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user,idx in userlist" :key="idx" >
                    <td class="auto-collapse">
                      <v-btn dark small color="green" draggable @dragstart="startDrag($event, idx)">
                        <v-icon dark> mdi-chevron-triple-left </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn dark small color="primary" @click="simUserClick(user.id, user.email)" >
                        <v-icon dark> mdi-account-convert </v-icon>
                        <!--v-icon dark> mdi-account-arrow-left </v-icon-->
                      </v-btn>
                    </td>
                    <td class="text-left">{{ user.email }}</td>
                    <td class="text-left">{{ user.fname }}</td>
                    <td class="text-left">{{ user.lname }}</td>
                    <td class="text-left auto-collapse">{{ user.admin }}</td>
                    <td>
                      <v-btn dark small color="error" @click="deleteUserClick(idx)">
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import MyTreeView from "../components/MyTreeView";
  import LoadingBar from "../components/LoadingBar";

  export default {
    name: "UsersPage",

    components: {
      MyTreeView,
      LoadingBar
    },
    data: () => ({
      dataCardHeight:0,

      datafetch_in_progress: false,
      selectedNode: null,
      isRecursiveFetch: false,

      addUserDialog: false,
      addUsrData: {"email": "", "fname":"", "lname":""},
      adduser_error_msg: null,

      deleteUserDialog: false,
      deleteUserIdx: -1,
      seletectedDelUsr: {},
      deluser_error_msg: null,

      moveUsertoOUDialog: false,
      moveUsertoOU_error_msg: null,
      selectedUsertoMove: {"id":null, "name": null, 'newsiteid': null, 'newsitename': null},
      
      userlist: [],
      users: [{"id":"", "email": "user1.lname1@test.com", "fname": "user1", "lname": "lname1", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
        {"id":"", "email": "user2.lname2@test.com", "fname": "user2", "lname": "lname2", "admin": "master"},
        {"id":"", "email": "user3.lname3@test.com", "fname": "user3", "lname": "lname3", "admin": "master"},
        {"id":"", "email": "user4.lname4@test.com", "fname": "user4", "lname": "lname4", "admin": "master"},
      ]
    }),
    created() {
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);
    },
    //destroyed() {
    beforeDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
      fetchUsers (recvdnode) {
        this.datafetch_in_progress = true;
        this.userlist = [];
        this.selectedNode = null;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/users/get',{"siteid":recvdnode.id, "recursive": this.isRecursiveFetch})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "list_company_users")
                {
                  this.userlist = response.data["company-users-list"];
                  this.selectedNode = recvdnode;

                  if (this.userlist == null) 
                    this.userlist = [];
                }
                //this.$emit('loadcomplete');
                this.datafetch_in_progress = false;
              });

        return;
      },
      simUserClick(userid, username){
        const newchilddata = {'code':'set_simulated_user', "userid":userid}
        //console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/users/setsim', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'set_simulated_user') {
                    if (response.data['success'])
                    {
                      const useraccessdict = response.data["USERACCESS"];
                      this.$store.commit("set_useraccessdict", useraccessdict);
                      this.$store.commit("set_user_sim", username);
                      //console.log(username);
                      this.$router.push("/login");
                    } else {
                      alert(response.data['errmsg']);
                    }
                  } else {
                    this.adduser_error_msg = "Invalid response!";
                  }
                });
      },
      addUserClose(){
        this.adduser_error_msg = null;
        this.addUserDialog = false;
      },
      addUserConfirm(){
        const parent = this.selectedNode;
        //console.log(parent);
        const newchilddata = {'code':'add_company_user', "pid":parent.id, "userdata":this.addUsrData}
        console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/users/add', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'add_company_user') {
                    if (response.data['success'])
                    {
                      const newchildname = response.data['uname'];
                      const newchildid = response.data['id'];
                      //const newchildabspath = response.data['abspath']; 
                      const newchildfname = response.data['fname']; 
                      const newchildlname = response.data['lname']; 
                      const newchilduser = {"id": newchildid, "email":newchildname, "fname":newchildfname,  "lname":newchildlname, "admin": "new"};
                      this.userlist.push(newchilduser);
                      this.addUserDialog = false;
                    } else {
                      this.adduser_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.adduser_error_msg = "Invalid response!";
                  }
                });
      },
      deleteUserClick(useridx){
        //this.seletectedDelUsr = this.userlist[useridx];
        this.deleteUserIdx = useridx;
        this.deluser_error_msg = null;
        this.deleteUserDialog = true;
      },
      deleteUserConfirm(){
        const parent = this.selectedNode;
        this.$api.post(this.$orgmanurl + '/users/delete', {'code':'del_company_user', "siteid":parent.id, "userid":this.userlist[this.deleteUserIdx]["id"]})
                .then(response => {
                  if (response.data['code'] == 'del_company_user') {
                    if (response.data['success'])
                    {
                      this.userlist.splice(this.deleteUserIdx, 1);
                      this.deleteUserDialog = false;
                    } else {
                      this.deluser_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.deluser_error_msg = "Invalid response!";
                  }
                });
      },
      startDrag(evt, useridx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        const userid = this.userlist[useridx].id;
        const username = this.userlist[useridx].fname + " " + this.userlist[useridx].lname;
        evt.dataTransfer.setData('id', userid);
        evt.dataTransfer.setData('name', username);
        console.log("Dragging UserID: " + userid + " : " + username);
      },
      onUserDrop(ucdict) {
        this.selectedUsertoMove = ucdict;
        this.moveUsertoOU_error_msg = ""; // Clear last error message
        this.moveUsertoOUDialog = true;
      },
      moveConfirmClick(){
        console.log("MoveConfirmed!");
        console.log(this.selectedUsertoMove);
        const userid = this.selectedUsertoMove['id'];
        const newsiteid = this.selectedUsertoMove['newsiteid'];
        this.$api.post(this.$orgmanurl + '/users/move', {'code':'move_user_company', "userid":userid, "newsiteid":newsiteid})
                .then(response => {
                  //console.log(response.data);
                  if (response.data['code'] == 'move_user_company') {
                    if (response.data['success'])
                    {
                      if (response.data['id'] === userid) {
                        // refresh userlist
                        for(var idx=0;idx<this.userlist.length;idx++) {
                          if (this.userlist[idx]["id"] == userid) {
                            this.userlist.splice(idx,1);
                            break;
                          }
                        }
                        this.moveUsertoOUDialog = false;
                      } else {
                        this.moveUsertoOU_error_msg = "Invalid Pump Response!";
                      }
                    } else {
                      this.moveUsertoOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.movePumptoOU_error_msg = "Invalid response!";
                  }
                });
      },
      resizeHandler(){//e
        const topbarHeight = 64;
        //const cardHeight = e.target.innerHeight-topbarHeight;
        const cardHeight = window.innerHeight-topbarHeight;
        //console.log(e.target.innerHeight,cardHeight);
        this.dataCardHeight = cardHeight;
      }
    },
  }
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0px;
}
@media (max-width:960px){
  .auto-collapse{
    display:none;
  }
}

.gtm-col-padding {
  padding: 3px;
}

*::-webkit-scrollbar {
  width: 3px;
} 
*::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
  
  border-radius: 3px;
} 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /*outline: 1px solid slategrey;*/
  border-radius: 3px;
}
</style>
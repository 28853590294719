<template>
    <v-container>
        <v-row>
            <v-col>
                <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
                <template v-if="pump['daq'].length > 0">
                    <div class="d-none d-md-block">
                        <template v-if="pumpIsDual">
                            <v-img src="@/assets/adk_hp_system_dual.png" ></v-img>
                            <div id="p2cwpc1f2i">{{ pump["daq"][p2cwpc1f2i].toFixed() }}</div>
                            <div id="p2c1tempi">{{ pump["daq"][p2c1tempi].toFixed(0) }}</div>
                            <div id="p2ss0vflowi">{{ pump["daq"][p2ss0vflowi].toFixed(0) }}</div>

                            <div id="p2cwpc2f2i">{{ pump["daq"][p2cwpc2f2i].toFixed() }}</div>
                            <div id="p2c2tempi">{{ pump["daq"][p2c2tempi].toFixed(0) }}</div>
                            <div id="p2ss1vflowi">{{ pump["daq"][p2ss1vflowi].toFixed(0) }}</div>

                            <div id="p1cwpc1f2i">{{ pump["daq"][p1cwpc1f2i].toFixed() }}</div>
                            <div id="p1c1tempi">{{ pump["daq"][p1c1tempi].toFixed(0) }}</div>
                            <div id="p1ss0vflowi">{{ pump["daq"][p1ss0vflowi].toFixed(0) }}</div>

                            <div id="p1cwpc2f2i">{{ pump["daq"][p1cwpc2f2i].toFixed() }}</div>
                            <div id="p1c2tempi">{{ pump["daq"][p1c2tempi].toFixed(0) }}</div>
                            <div id="p1ss1vflowi">{{ pump["daq"][p1ss1vflowi].toFixed(0) }}</div>

                            <div id="dvtempi">{{ pump["daq"][dvtempi].toFixed(0) }}</div>
                            <div id="dvpressi">{{ pump["daq"][dvpressi].toFixed(0) }}</div>

                            <div id="pestimi">{{ pump["daq"][pestimi].toFixed(0) }}</div>
                            <div id="flowfilt2i">{{ pump["daq"][flowfilt2i].toFixed() }}</div>
                            <div id="orificeestimi">{{ pump["daq"][orificeestimi].toFixed(0) }}</div>
                            <div id="orificespeci">{{ pump["daq"][orificespeci].toFixed(0) }}</div>
                        </template>
                        <div class="gtm-img-wrapper" v-else>
                            <v-img src="@/assets/adk_hp_system_single.png"></v-img>

                            <div id="p1cwpc1f2i_single">{{ pump["daq"][p1cwpc1f2i].toFixed() }}</div>
                            <div id="p1c1tempi_single">{{ pump["daq"][p1c1tempi].toFixed(0) }}</div>
                            <div id="p1ss0vflowi_single">{{ pump["daq"][p1ss0vflowi].toFixed(0) }}</div>

                            <div id="p1cwpc2f2i_single">{{ pump["daq"][p1cwpc2f2i].toFixed() }}</div>
                            <div id="p1c2tempi_single">{{ pump["daq"][p1c2tempi].toFixed(0) }}</div>
                            <div id="p1ss1vflowi_single">{{ pump["daq"][p1ss1vflowi].toFixed(0) }}</div>

                            <div id="dvtempi_single">{{ pump["daq"][dvtempi].toFixed(0) }}</div>
                            <div id="dvpressi_single">{{ pump["daq"][dvpressi].toFixed(0) }}</div>

                            <div id="pestimi_single">{{ pump["daq"][pestimi].toFixed(0) }}</div>
                            <div id="flowfilt2i_single">{{ pump["daq"][flowfilt2i].toFixed() }}</div>
                            <div id="orificeestimi_single">{{ pump["daq"][orificeestimi].toFixed(0) }}</div>
                            <div id="orificespeci_single">{{ pump["daq"][orificespeci].toFixed(0) }}</div>
                        </div>
                    </div>
                    <high-pressure-tab :pump="pump" class="d-block d-md-none" />
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';
import HighPressureTab from './HighPressureTab.vue';

export default {
  name: "ADKHPSystemTab",
  components: {
    HighPressureTab,
  },
  props: {
      pump: null
  },
  data(){
      return {
          u16hmistatei: null,

          instpumpsi: null,

          p1cwpc1f2i:null,p1cwpc2f2i:null,p2cwpc1f2i:null,p2cwpc2f2i:null,
          p1c1tempi:null,p1c2tempi:null,p2c1tempi:null,p2c2tempi:null,
          p1ss0vflowi:null,p1ss1vflowi:null,p2ss0vflowi:null,p2ss1vflowi:null,

          dvtempi:null,dvpressi:null,
          pestimi:null,flowfilt2i:null,orificeestimi:null,orificespeci:null
      }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");
    this.instpumpsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
    
    this.p1cwpc1f2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Reverse_Filt");
    this.p1cwpc2f2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Forward_Filt");
    this.p2cwpc1f2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Reverse_Filt");
    this.p2cwpc2f2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Forward_Filt");
    this.p1c1tempi = vhmi_mcdvars_list.indexOf("app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p1c2tempi = vhmi_mcdvars_list.indexOf("app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p2c1tempi = vhmi_mcdvars_list.indexOf("app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p2c2tempi = vhmi_mcdvars_list.indexOf("app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p1ss0vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate");
    this.p1ss1vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate");
    this.p2ss0vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate");
    this.p2ss1vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate");

    this.dvtempi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.dumpValveManager.thermalSensor_Dump.s16TempSense_Deg");
    this.dvpressi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.dumpValveManager.pressureSensor_Air.s16PressureSense_PSI_LPF32ms");

    this.pestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.pressureEstimator.s32Pressure_Estim");
    this.flowfilt2i = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt2");
    this.orificeestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_EstimInstalledInHead");
    this.orificespeci = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_Specified");
  },
  computed: {
      pumpIsDual() {
          return (this.pump["daq"][this.instpumpsi] == 2);
      }
  }
}
</script>

<style scoped>
.gtm-img-wrapper {
    position:relative;
    max-width: 100%;
    min-height:100%;
    height: auto;
    width: auto\9; /* ie8 */
}

#p2cwpc1f2i     { position:absolute; top:105px; left:60px; font-size: 16px; }
#p2c1tempi { position:absolute; top:145px; left:60px; font-size: 16px; }
#p2ss0vflowi { position:absolute; top:180px; left:60px; font-size: 16px; }

#p2cwpc2f2i     { position:absolute; top:100px; left:675px; font-size: 16px; }
#p2c2tempi { position:absolute; top:140px; left:675px; font-size: 16px; }
#p2ss1vflowi { position:absolute; top:175px; left:675px; font-size: 16px; }

#p1cwpc1f2i     { position:absolute; top:250px; left:60px; font-size: 16px; }
#p1c1tempi { position:absolute; top:290px; left:60px; font-size: 16px; }
#p1ss0vflowi { position:absolute; top:325px; left:60px; font-size: 16px; }

#p1cwpc2f2i     { position:absolute; top:245px; left:675px; font-size: 16px; }
#p1c2tempi { position:absolute; top:285px; left:675px; font-size: 16px; }
#p1ss1vflowi { position:absolute; top:320px; left:675px; font-size: 16px; }

#dvtempi     { position:absolute; top:420px; left:270px; font-size: 16px; }
#dvpressi     { position:absolute; top:460px; left:270px; font-size: 16px; }

#pestimi { position:absolute; top:410px; left:425px; font-size: 16px; }
#flowfilt2i { position:absolute; top:410px; left:500px; font-size: 16px; }
#orificeestimi { position:absolute; top:480px; left:410px; font-size: 16px; }
#orificespeci { position:absolute; top:480px; left:500px; font-size: 16px; }

#p1cwpc1f2i_single     { position:absolute; top:34px; left:60px; font-size: 16px; }
#p1c1tempi_single { position:absolute; top:74px; left:60px; font-size: 16px; }
#p1ss0vflowi_single { position:absolute; top:109px; left:60px; font-size: 16px; }

#p1cwpc2f2i_single     { position:absolute; top:29px; left:675px; font-size: 16px; }
#p1c2tempi_single { position:absolute; top:69px; left:675px; font-size: 16px; }
#p1ss1vflowi_single { position:absolute; top:104px; left:675px; font-size: 16px; }

#dvtempi_single     { position:absolute; top:204px; left:270px; font-size: 16px; }
#dvpressi_single     { position:absolute; top:244px; left:270px; font-size: 16px; }

#pestimi_single { position:absolute; top:194px; left:425px; font-size: 16px; }
#flowfilt2i_single { position:absolute; top:194px; left:500px; font-size: 16px; }
#orificeestimi_single { position:absolute; top:264px; left:410px; font-size: 16px; }
#orificespeci_single { position:absolute; top:264px; left:500px; font-size: 16px; }

</style>